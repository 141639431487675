<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          <strong>{{ registerName }}</strong>
          <!-- <back-to-list></back-to-list> -->

          <CButton style="float: right" color="dark" @click="redirectMethod">
            Back
          </CButton>
        </CCardHeader>
        <CCardBody>
          <CAlert v-if="err_msg != ''" :color="messageColor" closeButton :show.sync="dismissCountDown" fade>{{ err_msg }}
          </CAlert>
          <CTabs add-tab-classes="mt-1" v-if="otpVerified">
            <span @click="selected = 'Details'" v-if="checkPermission('list-profile')">

              <CTab active>
                <template slot="title" v-if="(type === 'Company' || type === 'Individual') && role === 'Admin'">
                  Admin
                </template>
                <template slot="title" v-else>
                  <template v-if="role == 'Admin'">
                    {{ $lang.profile.heading.members }}
                  </template>
                  <template v-else>
                    {{ $lang.profile.heading.member }}
                  </template>
                </template>
              </CTab>
            </span>

            <span @click="selected = 'ViewPlanPermissions'" v-if="checkPermission('list-profile') && role === 'User'">
              <CTab>
                <template slot="title">
                  Member Permissions
                </template>
              </CTab>
            </span>

            <span @click="selected = 'ProfileVerification'"
              v-if="checkPermission('view-profile-verification') && role === 'Admin'">
              <CTab>
                <template slot="title">
                  {{ $lang.profile.tab.verification }}
                </template>
              </CTab>
            </span>

            <span @click="selected = 'GroupChangeList'" v-if="checkPermission('list-profile')">
              <CTab>
                <template slot="title">
                  {{ $lang.profile.heading.jobDesignationRequests }}
                </template>
              </CTab>
            </span>

            <span v-if="role === 'Admin'" @click="selected = 'PlanDetail'">
              <CTab>
                <template slot="title">
                  {{ $lang.profile.heading.planDetail }}
                </template>
              </CTab>
            </span>

            <span @tabClick="setTabName('Branches')" @click="selected = 'Branches'"
              v-if="checkPermission('list-branch') && role === 'Admin' && type == 'Company'">
              <CTab>
                <template slot="title">
                  {{ $lang.profile.heading.branches }}
                </template>
              </CTab>
            </span>
            <!--            <span-->
            <!--              @click="selected = 'Details'"-->
            <!--              v-else-if="checkPermission('view-profile')"-->
            <!--            >-->
            <!--              <CTab active>-->
            <!--                <template slot="title">-->
            <!--                  {{ $lang.profile.tab.details }}-->
            <!--                </template>-->
            <!--              </CTab>-->
            <!--            </span>-->


            <!--            <span-->
            <!--                @click="selected = 'GroupChangeList'"-->
            <!--                v-if="type==='Company'"-->
            <!--            >-->
            <!--              <CTab>-->
            <!--                <template slot="title">-->
            <!--                 {{ $lang.profile.heading.groupChange }}-->
            <!--                </template>-->
            <!--              </CTab>-->
            <!--            </span>-->

            <span @click="selected = 'ProfileUpdateRequest'"
              v-if="checkPermission('list-profile-update-request') && role === 'Admin'">
              <CTab>
                <template slot="title">
                  {{ $lang.profile.tab.profile_request }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'ChildUsers'"
              v-if="checkPermission('list-profile') && role === 'Admin' && type == 'Company'">
              <CTab>
                <template slot="title">
                  {{ $lang.profile.heading.members }}
                </template>
              </CTab>
            </span>

            <span v-if="role === 'Admin'" @click="selected = 'MeetingList'">
              <CTab>
                <template slot="title">
                  {{ $lang.profile.heading.meetingList }}
                </template>
              </CTab>
            </span>
            <span v-if="role === 'Admin'" @click="selected = 'Groups'"><!--v-if="checkPermission('access-group')"-->
              <CTab>
                <template slot="title">
                  {{ $lang.profile.heading.community }}
                </template>
              </CTab>
            </span>

            <span @click="selected = 'Departments'"
              v-if="type == 'Company' && role === 'Admin'"><!--v-if="checkPermission('access-group')"-->
              <CTab>
                <template slot="title">
                  {{ $lang.profile.heading.departments }}
                </template>
              </CTab>
            </span>
            <!--            <span-->
            <!--                @click="selected = 'Groups'"-->

            <!--            >&lt;!&ndash;v-if="checkPermission('access-group')"&ndash;&gt;-->
            <!--              <CTab>-->
            <!--                <template slot="title">-->
            <!--                 {{ $lang.profile.heading.groups }}-->
            <!--                </template>-->
            <!--              </CTab>-->
            <!--            </span>-->
            <span v-if="type == 'Company' && role === 'Admin'"
              @click="selected = 'InterviewTemplates'"><!--v-if="checkPermission('access-group')"-->
              <CTab>
                <template slot="title">
                  {{ $lang.profile.heading.templates }}
                </template>
              </CTab>
            </span>

            <span v-if="type == 'Company' && role === 'Admin'"
              @click="selected = 'CompanyRatings'"><!--v-if="checkPermission('access-group')"-->
              <CTab>
                <template slot="title">
                  {{ $lang.profile.heading.ratings }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'Journey'" v-if="type === 'Company' && role === 'Admin'">
              <CTab>
                <template slot="title">
                  {{ $lang.profile.heading.journey }}
                </template>
              </CTab>
            </span>
          </CTabs>
          <component :is="selected" style="margin-top: 20px" v-bind:postId="currentId" :companyId="companyId"
            @changeSelected="getTab($event)" v-if="otpVerified"></component>
          

        </CCardBody>
      </CCard>

    </CCol>

  </CRow>
</template>

<script>
import { Mixin } from "/src/mixins/index.js";
import ProfileLinks from "./tab/ProfileLinks";
import ProfileVerification from "./tab/ProfileVerification";
import ProfileUpdateRequest from "./tab/ProfileUpdateRequest";
import Details from "./tab/Details";
import Branches from "./tab/Branches";
import GroupChangeList from "./tab/GroupChangeList";
import Journey from "./tab/Journey";
import PlanDetail from "./tab/PlanDetail";
import MeetingList from "./tab/MeetingList";
import ChildUsers from "./tab/ChildUsers";
import Groups from "./tab/Groups";
// import BackToList from "/src/views/backToList.vue";
import Departments from "./tab/Departments";
import InterviewTemplates from "./tab/InterviewTemplates";
import CompanyRatings from "./tab/CompanyRatings";
import ViewPlanPermissions from "./tab/ViewPlanPermissions";
import { serverBus } from "/src/main";
import required_span from "../../components/layouts/general/required-span";

export default {
  name: "ProfileTabs",
  mixins: [Mixin],
  components: {
    required_span,
    ViewPlanPermissions,
    Details,
    Branches,
    PlanDetail,
    ProfileVerification,
    ProfileUpdateRequest,
    ProfileLinks,
    ChildUsers,
    GroupChangeList,
    // BackToList,
    MeetingList,
    Groups,
    Journey,
    Departments,
    InterviewTemplates,
    CompanyRatings,
  },
  data() {
    return {
      err_msg: "",
      otpVerified: false,
      submitted: false,
      otp: "",
      selected: "Details",
      currentId: "",
      registerName: "",
      type: "", /* type Company or Individual*/
      role: "", // Admin  or User
      numberOfUsers: "",
      companyId: '',
      largeModal: true,
      messageColor: "success",
      dismissCountDown: 0,
      dismissCountDownS: 0,
      role : localStorage.getItem("roleName"),
    };
  },
  created() {
    let self = this;
    if (self.role === 'Admin') {
      self.otpVerified = true;
    } else {
      self.checkOtpExpiry();
      setInterval(function () {
        self.checkOtpExpiry();
      }, 60000);
    }

  },
  mounted() {
    let self = this;
    self.currentId = this.$route.params.id;
    
      serverBus.$on("send_company_id", function (companyId) {
        self.companyId = companyId;
      });
      serverBus.$on("number_of_admin_users", function (numberOfUsers) {
        self.numberOfUsers = numberOfUsers;
      });
      serverBus.$on("send_the_type", function (type) {
        self.type = type;
      });
      serverBus.$on("send_profile_name", function (name) {
        self.registerName = name;
      });
      serverBus.$on("send_role", function (role) {
        self.role = role;
      });
    
  },
  methods: {
    checkOtpExpiry() {
      let self = this;

      axios
        .post('/check-staff-otp-expiry', {})
        .then(function (response) {
          self.submitted = false; //Enable Button

          if (response.data.code === 200) {
            self.otpVerified = true;
            self.largeModal = false;
            self.err_msg = response.data.data.success;
            self.messageColor = "success";
            self.dismissCountDownS = 10;

          } else {
            self.otpVerified = false;
            self.messageColor = "danger";
            self.err_msg = response.data.message;
            self.dismissCountDown = 10;
            self.$router.push({ path: '/profile/otp/' + self.currentId });

          }
        })
        .catch(function (error) {
          let data;
          if (error.response.data.error) {
            data = error.response.data.error.toString();
          } else {
            data = error.response.data.message;
          }
          self.err_msg = data;
          self.dismissCountDown = 10;
          self.submitted = false; //Enable Button
          self.messageColor = "danger";
          self.$router.push({ path: '/profile/otp/' + self.currentId });

        });
    },
    
    
    redirectMethod() {
      this.$router.push({ path: '/profile' })
    },
    cancel() {
      //  this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/books'})
    },
    getTab(tab) {
      let self = this;
      self.selected = tab;
    },
    setTabName(name) {
      alert("TAB NAME:" + name);
      this.tabName = name;
    }
  },
};
</script>
<style>
div .error {
  color: #ff0000;
}

.hasError label {
  color: red;
}
</style>

